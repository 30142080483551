<app-header activeRoute="raskritie"></app-header>

<div class="block__title">
    <p class="h5">● GI Solutions</p>
    <h1 class="h2">Раскрытие информации</h1>
</div>

<div class="faq-wrapper">
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Полное и сокращенное фирменное наименование профессионального участника рынка
                ценных бумаг, в том числе на иностранном языке (при наличии двух последних)</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Общество с ограниченной ответственностью «Джи Ай Солюшенс», ООО «Джи Ай Солюшенс»
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 20.09.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Идентификационный номер налогоплательщика</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">7751205702
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 20.09.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Адрес профессионального участника рынка ценных бумаг, указанный в ЕГРЮЛ</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">108802, Г.Москва, ВН.ТЕР.Г. ПОСЕЛЕНИЕ СОСЕНСКОЕ, Д СОСЕНКИ, УЛ ЯСЕНЕВАЯ, Д. 7,
                ПОМЕЩ. 4, 4А
                <br>
                <br>Дата раскрытия информации на сайте —14.01.2022
                <br>Период актуальности — с 14.01.2022 до внесения изменений
                <br>‍
                <br><span style="color: #999999;">108814, Г.Москва, ВН.ТЕР.Г. ПОСЕЛЕНИЕ СОСЕНСКОЕ, Д СОСЕНКИ, УЛ
                    ЯСЕНЕВАЯ, Д. 7, ПОМЕЩ. 4, 4А</span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 29.10.2021</span>
                <br><span style="color: #999999;">Период актуальности — с 20.09.2021 до 13.01.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Номер телефона, факса (при наличии последнего) профессионального участника рынка
                ценных бумаг</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Телефон: +7 (495) 055-52-45
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 23.09.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Адрес электронной почты профессионального участника рынка ценных бумаг</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">infogi-solution@mail.ru
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 20.09.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Фамилия, имя, отчество (при наличии последнего) лица, осуществляющего функции
                единоличного исполнительного органа профессионального участника рынка ценных бумаг</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><strong>Генеральный директор
                    <br>Данилов Марк Владимирович</strong>
                <br>
                <br>‍ Дата раскрытия информации на сайте — 20.12.2022
                <br>Период актуальности — с 13.12.2022 до внесения изменений
                <br>
                <br><span style="color: #999999;">‍<strong>Генеральный директор
                        <br>Филипов Сергей Васильевич</strong></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте — 29.10.2021</span>
                <br><span style="color: #999999;">Период актуальности — с 20.09.2021 до 12.12.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Электронные копии всех лицензий на осуществление профессиональной деятельности
                на рынке ценных бумаг, созданные посредством сканирования</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/ЭП_выписка.pdf" target="_blank"
                    rel="noopener">Выписка из реестра профессиональных участников рынка ценных бумаг </a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.04.2023
                <br>Период актуальности — с 27.04.2023 до внесения изменений
                <br>
                <br><a class="txt-link" href="../../assets/ep_vypiska1.pdf"
                    target="_blank" rel="noopener">Выписка из реестра профессиональных участников рынка ценных бумаг
                </a>
                <br>
                <br>Дата раскрытия информации на сайте — 29.07.2022
                <br>Период актуальности — с 27.07.2022 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Vypiska_iz_reestra.pdf">Выписка из
                        реестра профессиональных участников рынка ценных бумаг </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 07.04.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 05.04.2022 до 26.07.2022</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/ep_vypiska.pdf" target="_blank"
                        rel="noopener">Выписка из реестра профессиональных участников рынка ценных бумаг </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 09.02.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 07.02.2022 до 04.04.2022</span>
                <br>
                <br><a class="txt-link"
                    href="../../assets/vypiska_291021.pdf">Выписка из единого
                    реестра инвестиционных советников </a>
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 29.10.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Информация о членстве в саморегулируемых организациях в сфере финансового рынка,
                объединяющих брокеров, дилеров, форекс-дилеров, управляющих, депозитариев, регистраторов (далее - СРО),
                в случае исключения из СРО профессиональный участник рынка ценных бумаг раскрывает информацию об этом с
                указанием даты и причины исключения</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">ООО «Джи Ай Солюшенс» — член Национальной ассоциации участников фондового рынка
                (НАУФОР)
                <br><a class="txt-link"
                    href="../../assets/Svidetelstvo_GI.pdf">Свидетельство о
                    членстве
                    <br></a> ООО «Джи Ай Солюшенс» при осуществлении своей деятельности на рынке ценных бумаг
                руководствуется обязательными для соблюдения действующими стандартами Национальной ассоциации участников
                фондового рынка (НАУФОР), расположенными по адресу: <a class="txt-link"
                    href="http://naufor.ru/tree.asp?n=4215" target="_blank"
                    rel="noopener">http://naufor.ru/tree.asp?n=4215 </a> , включая Внутренние стандарты НАУФОР,
                расположенными по адресу: <a class="txt-link" href="http://naufor.ru/tree.asp?n=12233" target="_blank"
                    rel="noopener"> http://naufor.ru/tree.asp?n=12233 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.06.2023
                <br>Период актуальности — с 21.06.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;">Член Национальной финансовой ассоциации (НФА) в отношении деятельности
                    управления ценными бумагами</span>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Svidetet_DU.pdf" target="_blank"
                        rel="noopener">Свидетельство о членстве </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 02.09.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 11.08.2022 до 17.05.2023</span>
                <br><span style="color: #999999;">Дата исключения — 18.05.2023</span>
                <br><span style="color: #999999;">Причина исключения — Решение Банка России от 18.15.2023 г. о
                    прекращении статуса саморегулируемой организации в сфере финансового рынка СРО НФА в отношении всех
                    видов деятельности.</span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 19.05.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 18.05.2023 до внесения изменений</span>
                <br>
                <br><span style="color: #999999;">Член Национальной финансовой ассоциации (НФА) в отношении депозитарной
                    деятельности</span>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Svidetet_DEPO.pdf">Свидетельство о
                        членстве </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 02.09.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 11.08.2022 до 17.05.2023</span>
                <br><span style="color: #999999;">Дата исключения — 18.05.2023</span>
                <br><span style="color: #999999;">Причина исключения — Решение Банка России от 18.15.2023 г. о
                    прекращении статуса саморегулируемой организации в сфере финансового рынка СРО НФА в отношении всех
                    видов деятельности.Дата раскрытия информации на сайте — 19.05.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 18.05.2023 до внесения изменений</span>
                <br>
                <br><span style="color: #999999;">Член Национальной финансовой ассоциации (НФА) в отношении брокерской
                    деятельности</span>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Svidetet_BD.pdf" target="_blank"
                        rel="noopener">Свидетельство о членстве</a></span>
                <br><span style="color: #999999;">‍</span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 16.05.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 12.05.2022 до 17.05.2023</span>
                <br><span style="color: #999999;">Дата исключения — 18.05.2023</span>
                <br><span style="color: #999999;">Причина исключения — Решение Банка России от 18.15.2023 г. о
                    прекращении статуса саморегулируемой организации в сфере финансового рынка СРО НФА в отношении всех
                    видов деятельности.</span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 19.05.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 18.05.2023 до внесения изменений</span>
                <br>
                <br><span style="color: #999999;">Член Национальной финансовой ассоциации (НФА) в отношении деятельности
                    по инвестиционному консультированию</span>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Svid_vo_GI.pdf" target="_blank"
                        rel="noopener">Свидетельство о членстве</a></span>
                <br>
                <br><span style="color: #999999;">Дата исключения — 29.12.2022</span>
                <br><span style="color: #999999;">Причина исключения — Решение Банка России от 29.12.2022 г. о
                    прекращении статуса саморегулируемой организации в сфере финансового рынка СРО НФА в отношении вида
                    деятельности по инвестиционному консультированию</span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 30.12.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 22.03.2022 до 29.12.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Перечень филиалов, представительств и иных обособленных подразделений,
                осуществляющих профессиональную деятельность на рынке ценных бумаг (при наличии), с указанием полного
                (при наличии - сокращенного) наименования, адреса, номера телефона, факса (при наличии последнего)</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Филиалы, представительства и иные обособленные подразделения отсутствуют
                <br>
                <br>Дата раскрытия информации на сайте — 29.10.2021
                <br>Период актуальности — с 20.09.2021 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Политика управления конфликтом интересов</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/politika_knf.pdf">Политика управления
                    конфликтом интересов Общества с ограниченной ответственностью «Джи Ай Солюшенс» </a>
                <br>
                <br>Дата раскрытия информации на сайте — 07.04.2022
                <br>Период актуальности — 07.04.2022 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Politic_upavl.pdf">Политика управления
                        конфликтом интересов Общества с ограниченной ответственностью «Джи Ай Солюшенс» </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 08.10.2021</span>
                <br><span style="color: #999999;">Период актуальности — с 08.10.2021 до 06.04.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Правила выявления и контроля конфликта интересов при осуществлении деятельности
                по инвестиционному консультированию, а также по предотвращению его последствий</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/prav_vyjavl.pdf">Правила выявления и
                    контроля конфликта интересов при осуществлении деятельности по инвестиционному консультированию, а
                    также по предотвращению его последствий Общества с ограниченной </a>
                <br>
                <br>Дата раскрытия информации на сайте — 27.09.2021
                <br>Период актуальности — с 29.10.2021 до 06.04.2022
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Критерии отнесения клиентов к категории лиц, на которых распространяется
                законодательство иностранного государства о налогообложении иностранных счетов, и способы получения от
                них необходимой информации</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/Kriter_nerezid.pdf">Критерии отнесения
                    клиентов к категории лиц, на которых распространяется законодательство иностранного государства о
                    налогообложении иностранных счетов, и способы получения от них необходимой информации </a>
                <br>
                <br>Дата раскрытия информации на сайте — 12.04.2022
                <br>Период актуальности — с 07.02.2022 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Перечень инсайдерской информации</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/Perech_inside.pdf">Перечень инсайдерской
                    информации </a>
                <br>
                <br>Дата раскрытия информации на сайте — 06.05.2022
                <br>Период актуальности — с 06.05.2022 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="./../assets/Perech_inside.pdf">Перечень
                        инсайдерской информации </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 12.04.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 07.02.2022 до 05.05.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Информация о стандартах СРО, которыми руководствуется профессиональный участник
                рынка ценных бумаг при осуществлении своей деятельности</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link" href="http://naufor.ru/tree.asp?n=12233" target="_blank"
                    rel="noopener">Внутренние стандарты СРО НАУФОР </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.06.2023
                <br>Период актуальности — с 21.06.2023 до внесения изменений
                <br>
                <br><a class="txt-link" href="http://naufor.ru/tree.asp?n=4215" target="_blank" rel="noopener">Базовые
                    стандарты </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.06.2023
                <br>Период актуальности — с 21.06.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;">Внутренние стандарты СРО НФА, действующие в отношении брокерской
                    деятельности и/или депозитарной деятельности и/или деятельности по управлению ценными
                    бумагами</span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 30.12.2021</span>
                <br><span style="color: #999999;">Период актуальности — с 29.12.2022 до 20.06.2023</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://new.nfa.ru/guide/internal-standards/" target="_blank" rel="noopener"> Внутренние
                        стандарты НФА</a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 16.05.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 12.05.2022 до 20.06.2023</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://new.nfa.ru/guide/index.php<a href=">Базовые стандарты </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 23.03.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 22.03.2022 до 20.06.2023</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Годовая бухгалтерская (финансовая) отчетность, представленная в налоговый орган,
                и аудиторское заключение по ней</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/audit_zakl_2022.pdf">Аудиторское заключение
                    за 2022 год </a>
                <br>
                <br>Дата раскрытия информации на сайте — 01.03.2023
                <br>Период актуальности — с 01.03.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="../../assets/audit_zakl_2022.pdf">Аудиторское заключение
                    за 2021 год </a>
                <br>
                <br>Дата раскрытия информации на сайте — 30.03.2022
                <br>Период актуальности — с 30.03.2022 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Промежуточная бухгалтерская (финансовая) отчетность (в случае ее составления)
            </div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a
                    href="../../assets/buh_3kv_2023_gi-solutions_sig.pdf">Бухгалтерская
                    (финансовая) отчетность за 3 квартал 2023 года</a>
                <br>
                <br>Дата раскрытия информации на сайте — 09.11.2023
                <br>Период актуальности — с 09.11.2023 до внесения изменений
                <br>
                <!-- <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/08/buh_2kv_2023_gi-solutions_sig.pdf">Бухгалтерская
                    (финансовая) отчетность за 2 квартал 2023 года </a>
                <br>
                <br>Дата раскрытия информации на сайте — 08.08.2023
                <br>Период актуальности — с 08.08.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/05/buh_1kv_2023_gi-solutions.pdf">Бухгалтерская
                    (финансовая) отчетность за 1 квартал 2023 года </a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.04.2023
                <br>Период актуальности — с 28.04.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/11/buh_3kv_2022_gi-solutions.pdf">Бухгалтерская
                    (финансовая) отчетность за 3 квартал 2022 года </a>
                <br>
                <br>Дата раскрытия информации на сайте — 11.11.2022
                <br>Период актуальности — с 11.11.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/08/buh_2kv_2022_gi-solutions.pdf">Бухгалтерская
                    (финансовая) отчетность за 2 квартал 2022 года </a>
                <br>
                <br>Дата раскрытия информации на сайте — 11.08.2022
                <br>Период актуальности — с 11.08.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/04/buh_1kv_2022_gi-solutions.pdf">Бухгалтерская
                    (финансовая) отчетность за 1 квартал 2022 года </a>
                <br>
                <br>Дата раскрытия информации на сайте — 26.04.2022
                <br>Период актуальности — с 26.04.2022 до внесения изменений -->
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Расчет собственных средств, осуществленный в соответствии с требованиями Банка
                России</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a
                    href="../../assets/ep_nso_purcb_m_30d_20231130_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.11.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 22.12.2023
                <br>Период актуальности — с 22.12.2023 до внесения изменений
                <br>
                <!-- <br><a
                    href="https://gi-solutions.ru/wp-content/uploads/2023/12/ep_nso_purcb_m_30d_20231031_0420413_s.pdf">Расчет
                    размера собственных средств профессионального участника на 31.10.2023 (скорректированный)</a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.12.2023
                <br>Период актуальности — с 28.12.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/11/ep_nso_purcb_m_30d_20231031_0420413.pdf">Расчет
                        размера собственных средств профессионального участника на 31.10.2023 </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 24.11.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 24.11.2023 до 27.12.2023</span>
                <br>
                <br><a
                    href="https://gi-solutions.ru/wp-content/uploads/2023/12/ep_nso_purcb_m_q_30d_20230930_0420413_s.pdf">Расчет
                    размера собственных средств профессионального участника на 30.09.2023 (скорректированный)</a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.12.2023
                <br>Период актуальности — с 28.12.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a style="color: #999999;"
                        href="http://gi-solutions.ru/wp-content/uploads/2023/10/ep_nso_purcb_m_q_30d_20230930_0420413.pdf">Расчет
                        размера собственных средств профессионального участника на 30.09.2023 </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 27.10.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 27.10.2023 до 27.12.2023</span>
                <br>
                <br><a
                    href="https://gi-solutions.ru/wp-content/uploads/2023/12/ep_nso_purcb_m_30d_20230831_0420413_s.pdf">Расчет
                    размера собственных средств профессионального участника на 31.08.2023 (скорректированный)</a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.12.2023
                <br>Период актуальности — с 28.12.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/09/ep_nso_purcb_m_30d_20230831_0420413-1.pdf">Расчет
                        размера собственных средств профессионального участника на 31.08.2023 </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 25.09.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 25.09.2023 до 27.12.2023</span>
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/08/ep_nso_purcb_m_30d_20230731_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.07.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.08.2023
                <br>Период актуальности — с 28.08.2023 до внесения изменений
                <br>
                <br><a
                    href="https://gi-solutions.ru/wp-content/uploads/2023/12/ep_nso_purcb_m_q_30d_20230630_0420413_s.pdf">Расчет
                    размера собственных средств профессионального участника на 30.06.2023 (скорректированный)</a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.12.2023
                <br>Период актуальности — с 28.12.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/07/ep_nso_purcb_m_q_30d_20230630_0420413.pdf">Расчет
                        размера собственных средств профессионального участника на 30.06.2023 </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 28.07.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 28.07.2023 до 27.12.2023</span>
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/06/ep_nso_purcb_m_30d_20230531_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.05.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 27.06.2023
                <br>Период актуальности — с 27.06.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/05/ep_nso_purcb_m_30d_20230430_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.04.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 29.05.2023
                <br>Период актуальности — с 29.05.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/04/ep_nso_purcb_m_q_30d_mal_20230331_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.03.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 26.04.2023
                <br>Период актуальности — с 26.04.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/03/ep_nso_purcb_m_30d_mal_20230228_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 28.02.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 22.03.2023
                <br>Период актуальности — с 22.03.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/02/ep_nso_purcb_m_30d_mal_20230131_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.01.2023 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 27.02.2023
                <br>Период актуальности — с 27.02.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/01/ep_nso_purcb_m_y_30d_20221231_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.12.2022 </a>
                <br>‍
                <br>Дата раскрытия информации на сайте — 27.01.2023
                <br>Период актуальности — с 27.01.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/12/ep_nso_purcb_m_30d_20221130_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.11.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 27.12.2022
                <br>Период актуальности — с 27.12.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/11/ep_nso_purcb_m_30d_20221031_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.10.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 17.11.2022
                <br>Период актуальности — с 17.11.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/10/ep_nso_purcb_m_q_30d_20220930_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.09.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 24.10.2022
                <br>Период актуальности — с 24.10.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/09/ep_nso_purcb_m_30d_20220831_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.08.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.09.2022
                <br>Период актуальности — с 23.09.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/08/ep_nso_purcb_m_30d_20220731_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.07.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 26.08.2022
                <br>Период актуальности — с 26.08.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/07/ep_nso_purcb_m_q_30d_20220630_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.06.2022
                    <br></a>
                <br>Дата раскрытия информации на сайте — 27.07.2022
                <br>Период актуальности — с 27.07.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/06/ep_nso_purcb_m_30d_20220531_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.05.2022
                    <br></a>
                <br>Дата раскрытия информации на сайте — 20.06.2022
                <br>Период актуальности — с 20.06.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/05/ep_nso_purcb_m_30d_20220430_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 30.04.2022
                    <br></a>
                <br>Дата раскрытия информации на сайте — 25.05.2022
                <br>Период актуальности — с 25.05.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/04/ep_nso_purcb_m_q_30d_20210331_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 31.03.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 21.04.2022
                <br>Период актуальности — с 21.04.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2022/03/ep_nso_purcb_m_30d_20220228_0420413.pdf">Расчет
                    размера собственных средств профессионального участника на 28.02.2022 </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.03.2022
                <br>Период актуальности — с 23.03.2022 до внесения изменений -->
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Указание на то, что брокер является клиентским брокером в соответствии с
                Указанием Банка России от 25 июля 2014 года N 3349-У "О единых требованиях к правилам осуществления
                брокерской деятельности при совершении операций с имуществом клиента брокера", зарегистрированным
                Министерством юстиции Российской Федерации 25 августа 2014 года N 33865 (Вестник Банка России от 10
                сентября 2014 года N 81). Информация раскрывается только клиентским брокером</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">«ООО «Джи Ай Солюшенс» не является клиентским брокером»
                <br>
                <br>Дата раскрытия информации на сайте — 07.04.2022
                <br>Период актуальности — с 05.04.2022 до внесения изменений
                <br>
                <br><span style="color: #999999;">ООО «Джи Ай Солюшенс» является клиентским брокером</span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 08.02.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 07.02.2022 до 04.04.2022</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Перечень кредитных организаций, в которых брокеру открыты специальные брокерские
                счета, с указанием полного фирменного наименования кредитной организации</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">«Газпромбанк» (Акционерное общество)
                <br>
                <br>Дата раскрытия информации на сайте — 13.04.2023
                <br>Период актуальности — с 12.04.2023 до внесения изменений
                <br>
                <br>Закрытое акционерное общество «Ардшинбанк»
                <br>‍
                <br>Дата раскрытия информации на сайте — 10.04.2023
                <br>Период актуальности — с 07.04.2023 до внесения изменений
                <br>
                <br>Небанковская кредитная организация акционерное общество «Национальный расчетный депозитарий»
                <br>
                <br>Дата раскрытия информации на сайте — 24.03.2022
                <br>Период актуальности — с 17.03.2022 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Перечень организаций, в которых управляющему открыты лицевые счета (счета депо)
                доверительного управляющего, с указанием полного фирменного наименования организации</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Небанковская кредитная организация акционерное общество «Национальный расчетный
                депозитарий»
                <br>
                <br>Дата раскрытия информации на сайте — 23.03.2023
                <br>Период актуальности — с 23.03.2023 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Перечень регистраторов и депозитариев, в том числе иностранных, в которых
                депозитарию открыты лицевые счета (счета депо) номинального держателя, с указанием полного фирменного
                наименования регистратора или депозитария</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Закрытое акционерное общество «Ардшинбанк»
                <br>
                <br>Дата раскрытия информации на сайте — 10.04.2023
                <br>Период актуальности — с 07.04.2023 до внесения изменений
                <br>
                <br>Небанковская кредитная организация акционерное общество «Национальный расчетный депозитарий»
                <br>
                <br>Дата раскрытия информации на сайте — 23.03.2023
                <br>Период актуальности — с 23.03.2023 до внесения изменений
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Клиринг и Торговая деятельность</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Перечень организаторов торговли, где брокер является участником торгов
                <br>
                <br>Публичное акционерное общество «Московская Биржа ММВБ-РТС»
                <br>
                <br>Дата раскрытия информации на сайте — 03.05.2023
                <br>Период актуальности — с 03.05.2023 до внесения изменений
                <br>
                <br>Информация о приостановлении допуска брокера к организованным торгам
                <br>Приостановление допуска ООО «Джи Ай Солюшенс» к организованным торгам отсутствует.
                <br>
                <br>Перечень клиринговых организаций, с которыми брокер заключил договоры об оказании ему клиринговых
                услуг:
                <br>Небанковская кредитная организация-центральный контрагент «Национальный Клиринговый Центр»
                (Акционерное общество)
                <br>
                <br>Дата раскрытия информации на сайте — 17.04.2023
                <br>Период актуальности — с 04.04.2023 до внесения изменений
                <br>
                <br>Небанковская кредитная организация акционерное общество «Национальный расчетный депозитарий»
                <br>
                <br>Дата раскрытия информации на сайте — 05.04.2023
                <br>Период актуальности — с 23.03.2023 до внесения изменений
                <br>
                <br>Информация о приостановлении допуска брокера к клиринговому обслуживанию
                <br>Приостановление допуска ООО «Джи Ай Солюшенс» к клиринговому обслуживанию отсутствует.
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Информация для клиентов, признанных квалифицированными инвесторами</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Вы вправе подать заявление ООО «Джи Ай Солюшенс» об исключении Вас из реестра лиц,
                признанных квалифицированными инвесторами. В этом случае Вы лишитесь возможности приобретать ценные
                бумаги, предназначенные для квалифицированных инвесторов, и заключать договоры, являющиеся производными
                финансовыми инструментами, предназначенные для квалифицированных инвесторов, в отношении которых Вы были
                признаны квалифицированным инвестором, пользуясь услугами ООО «Джи Ай Солюшенс».
                <br>
                <br>Подать заявление ООО «Джи Ай Солюшенс» об исключении Вас из реестра лиц можно лично в офисе ООО «Джи
                Ай Солюшенс» по адресу: г. Москва, вн. тер. гор. поселение Сосенское, д. Сосенки, ул. Ясеневая, д. 7,
                пом. 4, 4А, либо путем направления электронного документа со своего авторизованного адреса электронной
                почты по адресу электронной почты &nbsp;infogi-solution@mail.ru.
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Информация, предназначенная получателям финансовых услуг</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/09/Anketa_UL_02.10.23.docx">Анкета
                    юридического лица </a>
                <br><a class="txt-link" href="https://gi-solutions.ru/wp-content/uploads/2023/05/Анкета-ФЛ.docx"
                    target="_blank" rel="noopener">Анкета физического лица</a>
                <br>
                <br>‍ Дата раскрытия информации на сайте 29.09.2023
                <br>Период актуальности — с 02.10.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/08/Anketa_UL.docx" target="_blank"
                        rel="noopener">Анкета юридического лица </a></span>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/05/Анкета-ФЛ.docx" target="_blank"
                        rel="noopener">Анкета физического лица</a></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте 20.04.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 20.04.2023 по 29.09.2023</span>
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/10/Zayavlenie_zakl_dog_18102023.doc">Заявление
                    о заключении брокерского договора</a>
                <br>
                <br>‍ Дата раскрытия информации на сайте — 03.10.2023
                <br>Период актуальности — с 18.10.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/10/Pril1_zayavlenie_o_zakl_dogovora_01072023.doc">Заявление
                        о заключении брокерского договора </a></span>
                <br><span style="color: #999999;">‍</span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 22.06.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 01.07.2023 до 17.10.2023.</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/10/Pril1-ZZD_с27042023.doc">Заявление о
                        заключении брокерского договора</a></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте 27.04.2023</span>
                <br><span style="color: #999999;">Период актуальности —с 27.04.2023 до 30.06.2023.</span>
                <br>
                <br><a class="txt-link"
                    href="../../assets/Inform_23062023.pdf">Информация и
                    документы, предоставляемые получателям финансовых услуг, которыми руководствуется профессиональный
                    участник рынка ценных бумаг при осуществлении своей деятельности в рамках базового стандарта защиты
                    прав и интересов физических и юридических лиц – получателей финансовых услуг. </a>
                <br>
                <br>Дата раскрытия информации на сайте — 23.06.2023
                <br>Период актуальности — с 21.06.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/Информация-для-клиентов-офис-и-сайт.pdf">Информация
                        и документы, предоставляемые получателям финансовых услуг, которыми руководствуется
                        профессиональный участник рынка ценных бумаг при осуществлении своей деятельности в рамках
                        базового стандарта защиты прав и интересов физических и юридических лиц – получателей финансовых
                        услуг. </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 25.04.2023</span>
                <br><span style="color: #999999;">Период актуальности — c 25.04.2023 до 20.06.2023</span>
                <br>
                <br>Документы, подлежащие предоставлению клиентом при заключении брокерского/депозитарного договора:
                <br>‍
                <br><a class="txt-link"
                    href="../../assets/Перечень-документовЮЛ.pdf">юридические лица
                </a>
                <br><a class="txt-link"
                    href="../../assets/Перечень-документовФЛ.pdf">физические лица
                </a>
                <br>
                <br>Дата раскрытия информации на сайте — 25.04.2023
                <br>Период актуальности — с 25.04.2023 до внесения изменений
                <br>
                <br>‍<a
                    href="../../assets/2024-01-09_reglament_brok_obsl.pdf">Регламент
                    брокерского обслуживания клиентов ООО «Джи Ай Солюшенс»</a>
                <br>‍ <a
                    href="https://gi-solutions.ru/wp-content/uploads/2023/12/2024-01-09_prilogeniya_k_reglamentu.rar">Приложения
                    к Регламенту</a>
                <br>
                <br>‍ Дата раскрытия информации на сайте — 14.12.2023
                <br>Период актуальности — с 09.01.2024 до внесения изменений
                <br>
                <br><span style="color: #999999;">‍<a class="txt-link" style="color: #999999;"
                        href="../../assets/Reglament_brok_obsl-klientov_181023.pdf">Регламент
                        брокерского обслуживания клиентов ООО «Джи Ай Солюшенс»
                        <br>‍ </a> <a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/06/Pril_regl_01_07_2023.zip">Приложения к
                        Регламенту</a></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте — 03.10.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 18.10.2023 до 08.01.2024</span>
                <br>
                <br><span style="color: #999999;">‍<a class="txt-link" style="color: #999999;"
                        href="../../assets/Reglament_brok_obsl-klientov_010723.pdf">Регламент
                        брокерского обслуживания клиентов ООО «Джи Ай Солюшенс»
                        <br>‍ </a> <a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/10/Pril_regl_01_07_2023.zip">Приложения к
                        Регламенту</a></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте — 16.06.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 01.07.2023 до 17.10.2023</span>
                <br>
                <br><span style="color: #999999;">‍<a class="txt-link" style="color: #999999;"
                        href="../../assets/Reglam_BO_с27042023-1.pdf">Регламент
                        брокерского обслуживания клиентов ООО «Джи Ай Солюшенс»
                        <br>‍ </a> <a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/10/Pril_k_reglamentu_BO_27_04_2023.zip">Приложения
                        к Регламенту</a></span>
                <br>
                <br><span style="color: #999999;">‍ Дата раскрытия информации на сайте — 27.04.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 27.04.2023 по 30.06.2023</span>
                <br>
                <br>‍<a class="txt-link"
                    href="../../assets/Политика-соверш-торг-опер200423.pdf">Политика
                    совершения торговых операций за счет Клиентов</a>
                <br>
                <br>Дата раскрытия информации на сайте — 21.04.2023
                <br>Период актуальности — с 21.04.2022 до внесения изменений
                <br>
                <!-- <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/04/info_doc_akcii_obykn.pdf">Информационный
                    документ об обыкновенной акции российского акционерного общества </a>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/04/info_doc_akcii_priv.pdf">Информационный
                    документ о привилегированной акции российского акционерного общества </a>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/04/info_doc_fush_dog.pdf">Информационный
                    документ о фьючерсном договоре (контракте) </a>
                <br>
                <br>Дата раскрытия информации на сайте — 05.04.2023
                <br>Период актуальности — с 03.04.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/07/inf_doc_rsp_147072023.pdf">Информационный
                    документ об иностранной депозитарной расписке на акции
                    <br></a> <a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/07/inf_doc_OO_14072023.pdf">Информационный
                    документ об обыкновенной облигации российского эмитента </a>
                <br><a class="txt-link"
                    href="https://gi-solutions.ru/wp-content/uploads/2023/07/inf_doc_ofz_14072023.pdf">Информационной
                    документ об облигации федерального займа для физических лиц (ОФЗ-н) </a>
                <br>
                <br>Дата раскрытия информации — 20.07.2023
                <br>Период актуальности — с 20.07.2023 до внесения изменений -->
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Доверительное управление</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a
                    href="../../assets/2022-05-06_Politika_osush_prav.pdf">Политика
                    осуществления прав по ценным бумагам</a>
                <br>
                <br>Дата раскрытия информации на сайте — 01.08.2022
                <br>Период актуальности — с 01.08.2022 до внесения изменений
                <br>
                <br><a href="../../assets/2022-05-06_Perechen_mer.pdf">Перечень
                    мер, предпринимаемых ООО «Джи Ай Солюшенс», по недопущению установления приоритета интересов одного
                    или нескольких учредителей управления над интересами других учредителей управления</a>
                <br>Дата раскрытия информации на сайте — 01.08.2022
                <br>Период актуальности — с 01.08.2022 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="../../assets/Poryadok_opredeleniya_invest_portf_10.2023.pdf">Порядок
                    определения инвестиционного профиля клиента
                    <br></a>Дата раскрытия информации на сайте — 03.10.2023
                <br>Период актуальности — с 13.10.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a style="color: #999999;"
                        href="../../assets/2022-05-06_Poryadok_opred_IP.pdf">Порядок
                        определения инвестиционного профиля клиента</a></span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 01.08.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 01.08.2022 до 12.10.2023</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Технические сбои</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Информация о технических сбоях в автоматизированных системах профессионального
                участника рынка ценных бумаг
                <br>
                <br>Технические сбои отсутствуют.
                <br>
                <br>Информация о возобновлении работоспособности автоматизированных систем профессионального участника
                рынка ценных бумаг после сбоев
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Документ, содержащий порядок принятия решения о признании лица квалифицированным
                инвестором (при наличии)</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/2023-02-28_reg_prizn_lic_Ki.pdf">Регламент
                    принятия решения о признании лица квалифицированным инвестором Общества с ограниченной
                    ответственностью «Джи Ай Солюшенс» </a>
                <br>
                <br>Дата раскрытия информации на сайте — 28.02.2023
                <br>Период актуальности — с 01.03.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/reglame_inv.pdf" target="_blank"
                        rel="noopener">Регламент принятия решения о признании лица квалифицированным инвестором Общества
                        с ограниченной ответственностью «Джи Ай Солюшенс» </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 08.02.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 07.02.2022 до 28.02.2023</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Порядок направления жалоб и обращений</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph">Клиент имеет право направлять обращения (жалобы) в ООО «Джи Ай Солюшенс» по адресу
                электронной почты: infogi-solution@mail.ru;
                <br>
                <br>Клиент имеет право направлять обращения (жалобы) в Банк России, который является надзорным органом
                ООО «Джи Ай Солюшенс»:
                <br>через интернет- приемную <a class="txt-link" href="https://cbr.ru/reception/" target="_blank"
                    rel="noopener">https://cbr.ru/reception/ </a> ;
                <br>лично по адресу: Москва, Сандуновский пер., д. 3, стр. 1
                <br>почтовым отправлением по адресу: 107016, Москва, ул. Неглинная, д. 12, Банк России.
                <br>
                <br>Клиент имеет право направлять обращения (жалобы) в саморегулируемую организацию НАУФОР, членом
                которой является ООО «Джи Ай Солюшенс» :
                <br>по адресу: Москва, 129090, 1-й Коптельский пер., д. 18, стр.1
                <br>по телефону: 8 495 787 77 75
                <br>по E-mail: info@naufor.ru
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Условия осуществления депозитарной деятельности</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a
                    href="../../assets/2024-01_klientskiy_-reglament_depo_09.01.2024.pdf">Условия
                    осуществления депозитарной деятельности депозитария ООО «Джи Ай Солюшенс» </a>
                <br>
                <br>Дата раскрытия информации на сайте — 15.12.2023
                <br>Период актуальности – с 09.01.2024 до внесений изменений
                <br>
                <!-- <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/10/Klientskiy_regl_dep_24102023.pdf">Условия
                        осуществления депозитарной деятельности депозитария ООО «Джи Ай Солюшенс» </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 09.10.2023</span>
                <br><span style="color: #999999;">Период актуальности – с 24.10.2023 до 08.01.2024</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2022/07/2022-05-06_klientskiy_regl_deposit.pdf">Условия
                        осуществления депозитарной деятельности депозитария ООО «Джи Ай Солюшенс» </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 29.07.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 27.07.2022 до 23.10.2023</span> -->
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Образец договора</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/2023-Депозитарный_договор.pdf">Образец
                    депозитарного договора &nbsp; </a>
                <br>
                <br>Дата раскрытия информации на сайте — 12.05.2023
                <br>Период актуальности — с 12.05.2023 до внесения изменений
                <br>
                <br><a class="txt-link"
                    href="../../assets/2023-междепозитарный_договор.pdf">Образец
                    междепозитарного договора &nbsp; </a>
                <br>
                <br>Дата раскрытия информации на сайте — 12.05.2023
                <br>Период актуальности — с 12.05.2023 до внесения изменений
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="../../assets/2022-06-16_Депозитарный_договор.pdf">Образец
                        депозитарного договора &nbsp; </a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 25.04.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 25.04.2023 до 11.05.2023</span>
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
    <div class="faq-question-wrap" (click)="openAnswer($event)">
        <a  class="faq-question-bar w-inline-block">
            <div class="question-title">Декларация о рисках</div>
            <div class="faq-circle">
                <img src="../../assets/plus.svg"
                    class="plus-icon" alt="">
            </div>
        </a>
        <div class="faq-content">
            <p class="faq-paragraph"><a class="txt-link"
                    href="../../assets/2023-07-14_declr_risk.pdf">Декларация о
                    рисках
                    <br></a>
                <br>Дата раскрытия информации на сайте — 03.07.2023
                <br>Период актуальности — с 14.07.2023 до внесения изменений
                <br>
                <!-- <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/05/2023-05-29_Декларация_о_рисках.pdf">Декларация
                        о рисках
                        <br></a></span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 29.05.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 09.06.2023 по 13.07.2023</span>
                <br>
                <br><span style="color: #999999;"><a class="txt-link" style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2023/03/2023-02-28_decl_risk.pdf">Декларация о
                        рисках
                        <br></a></span>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 28.02.2023</span>
                <br><span style="color: #999999;">Период актуальности — с 01.03.2023 до 08.06.2023</span>
                <br>
                <br><span style="color: #999999;"><a style="color: #999999;"
                        href="https://gi-solutions.ru/wp-content/uploads/2022/08/2022-05-06_Deklarac_risk.pdf">Декларация
                        о рисках</a></span>
                <br>
                <br><span style="color: #999999;">Дата раскрытия информации на сайте — 01.08.2022</span>
                <br><span style="color: #999999;">Период актуальности — с 01.08.2022 по 28.02.2023</span> -->
            </p>
            <div class="div-block-5"></div>
        </div>
    </div>
</div>