<app-header activeRoute="about"></app-header>
<div class="block__title">
    <p class="h5">● GI Solutions</p>
    <h1 class="h2">О компании</h1>
    <p class="txt center-align">Мы специализируемся на комплексном обслуживании институциональных и крупных частных
        инвесторов. Компания осуществляет деятельность на рынке ценных бумаг, является членом Национальной ассоциации
        участников фондового рынка (НАУФОР) и руководствуется обязательными для соблюдения действующими стандартами
        ассоциации. Основана в 2021 году</p>
</div>
<div class="ceo__column w-row">
    <div class="ceo__column__img w-col w-col-6">
        <h1 class="h3">Уважаемые клиенты и будущие партнеры!</h1>
    </div>
    <div class="ceo__column_content w-col w-col-6">
        <div class="ceo__content">
            <p class="txt">
                В мире быстро меняющихся финансовых рынков и&nbsp;неопределенности, мы стремимся быть надежными
                проводниками к&nbsp;устойчивому будущему.
            </p>
            <p class="txt">
                Ваш успех — наша главная цель. Для ее достижения мы создаем среду, в&nbsp;которой успешные инвестиции
                становятся стандартом, а&nbsp;инновации — неотъемлемым элементом.
            </p>
            <p class="txt">
                Наши отношения построены на основе долгосрочного сотрудничества и&nbsp;взаимной выгоды, и мы гордимся
                каждым успешным этапом нашего пути вместе.
            </p>
        </div>
        <div class="ceo__content_title">
            <p class="h5 left-align">Генеральный директор</p>
            <h1 class="h4">Данилов Марк Владимирович</h1>
        </div>
    </div>
</div>

<div class="block__title">
    <p class="h5">● Почему именно мы</p>
    <h1 class="h2">Лицензии компании</h1>
    <p class="txt center-align">
        <span class="doc-btn"  *ngIf="!isDocsShowing" (click)="showDocs()">Показать лицензии</span>
        <span class="doc-btn" *ngIf="isDocsShowing" (click)="showDocs()">Закрыть лицензии</span>
    </p>
</div>

<div class="documents"  *ngIf="isDocsShowing">
    <div class="content-wrapper">
        <div class="doc-container">
            <div class="doc-item" (click)="openDocModal1()">
                <img src="../../assets/MR1.jpg" alt="">
            </div>
            <div class="doc-item" (click)="openDocModal2()">
                <img src="../../assets/MR2.jpg" alt="">
            </div>
            <div class="doc-item" (click)="openDocModal3()">
                <img src="../../assets/MR3.jpg" alt="">
            </div>
            <div class="doc-item" (click)="openDocModal4()">
                <img src="../../assets/MR4.jpg" alt="">
            </div>
        </div>

    </div>
</div>

<div class="block__title">
    <p class="h5">● Чем мы руководствуемся</p>
    <h1 class="h2">Стандарты компании</h1>
    <p class="txt center-align">
        Мы стремимся предоставить каждому клиенту уникальное инвестиционное решение на основе комплексного подхода и
        передовых технологий, чтобы помочь достичь своих финансовых целей и построить надежную основу для финансового
        благополучия </p>
</div>

<div class="w-layout-grid card__wrapper">
    <div class="card">
        <img src="../../assets/doverie.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Доверие</h1>
            <p class="txt">Мы строим отношения на основе доверия и прозрачности, уделяя первоочередное внимание
                интересам клиентов</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/budushee.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Будущее</h1>
            <p class="txt">Наша работа направлена на создание будущего, в котором наши клиенты могут чувствовать себя
                уверенно и успешно</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/profess.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Профессионализм</h1>
            <p class="txt">Наша команда состоит из высококвалифицированных специалистов, которые поддерживают высокие
                стандарты качества в работе</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/individ-dohod.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Индивидуальный подход</h1>
            <p class="txt">Мы признаем уникальность каждого клиента и разрабатываем стратегии, соответствующие
                индивидуальным финансовым целям</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/unikal.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Уникальность</h1>
            <p class="txt">Мы стремимся создавать инвестиционные решения, которые отличаются от общепринятых и
                способствуют достижению эксклюзивных результатов</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/compleks.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Комплексные задачи и решения</h1>
            <p class="txt">Мы специализируемся на решении сложных и многогранных финансовых задач, предоставляя нашим
                клиентам всестороннюю поддержку</p>
        </div>
    </div>
</div>

<div class="doc-item-modal" id="docModal1" (click)="closeDocModal1()">
    <div class="img-wrapper">
        <img src="../../assets/MR1.jpg" alt="">
    </div>
</div>
<div class="doc-item-modal" id="docModal2" (click)="closeDocModal2()">
    <div class="img-wrapper">
        <img src="../../assets/MR2.jpg" alt="">
    </div>
</div>
<div class="doc-item-modal" id="docModal3" (click)="closeDocModal3()">
    <div class="img-wrapper">
        <img src="../../assets/MR3.jpg" alt="">
    </div>
</div>
<div class="doc-item-modal" id="docModal4" (click)="closeDocModal4()">
    <div class="img-wrapper">
        <img src="../../assets/MR4.jpg" alt="">
    </div>
</div>