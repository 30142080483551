<app-header></app-header>
<div class="column__banner w-row">
    <div class="column__banner_content w-col w-col-6">
        <h1 class="h1"><span class="h1__span">Индивидуальные решения</span> для институциональных и&nbsp;частных
            инвесторов</h1>
        <div class="marker-list">
            <div class="marker-list__item">
                <img src="../../assets/check.svg" class="icon__check" alt="">
                <p class="h5 dark">Выход на рынки капитала</p>
            </div>
            <div class="marker-list__item">
                <img src="../../assets/check.svg" class="icon__check" alt="">
                <p class="h5 dark">Персональный подход к запросам клиентов</p>
            </div>
            <div class="marker-list__item">
                <img src="../../assets/check.svg" class="icon__check" alt="">
                <p class="h5 dark">Управление крупными инвестиционными портфелями</p>
            </div>
        </div>
        <p class="txt">Профессионализм, инновации и опыт позволяют нам замечать инвестиционные возможности, решать
            нестандартные задачи и&nbsp;обеспечивать финансовое благополучие</p>
    </div>

    <div class="column__banner_img w-col w-col-6" (click)="openBank()">
        <div class="banner__img"></div>
        <div class="white-mask"></div>
        <div class="text">Проверка контрагента
            <br> ООО "ДЖИ АЙ СОЛЮШЕНС"
        </div>
        <div class="small-text">
            <br> Уважаемые клиенты, 
            <br>
            Вынуждены вам сообщить, что в связи с санкциями, платежные системы Visa и Mastercard временно остановили работу на территории РФ от 10.03.2022, а также наш рынок покинули ряд криптобирж и кошельков: Binance, MetaMask, Kraken и другие. В следствии, временно невозможно осуществлять платежи (или: финансовые операции) с использованием указанных платежных систем и криптокошельков. 
            <br>
            Мы рекомендуем вам воспользоваться криптокошельком <a href="https://www.bybit.com/ru-RU">Bybit</a> - нашим надежным партнером в мире финансовых операций. 
            <br>
            Пожалуйста, следите за обновлениями на нашем сайте, чтобы быть в курсе актуальной информации о доступных способах оплаты. 
            <br>
            Благодарим за ваше внимание. 
            <br>
            С уважением, 
            <br>
            "ДЖИ АЙ СОЛЮШЕНС"
        </div>
    </div>

</div>

<div class="block__title">
    <p class="h5">● Что мы предлагаем</p>
    <h1 class="h2">Взаимовыгодное сотрудничество<br><span class="h2__span">строится на доверии и&nbsp;преданности вашим
            финансовым целям</span></h1>
    <p class="txt center-align">Полный спектр услуг для решения инвестиционных задач</p>
</div>

<div class="w-layout-grid card__wrapper">
    <div class="card">
        <img src="../../assets/dover-uprav.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Доверительное<br>управление</h1>
            <p class="txt">Разрабатываем индивидуальные инвестиционные стратегии управления большим капиталом</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/broker-det.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Брокерская<br>деятельность</h1>
            <p class="txt">Обеспечиваем доступ к широкому спектру финансовых инструментов и предоставляем
                профессиональную поддержку для достижения финансовых целей</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/invest-cons.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Инвестиционное<br>консультирование</h1>
            <p class="txt">Помогаем принимать взвешенные инвестиционные решения</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/diler-deyat.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Дилерская<br>деятельность</h1>
            <p class="txt">Предоставляем доступ к&nbsp;финансовым рынкам и&nbsp;инструментам</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/deposit-deyat.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Депозитарная<br>деятельность</h1>
            <p class="txt">Обеспечиваем надежное и безопасное хранение ваших активов</p>
        </div>
    </div>
    <div class="card">
        <img src="../../assets/reshen-invest.svg" class="card__icon" alt="">
        <div class="card__content">
            <h1 class="h4">Решение ваших инвестиционных задач</h1>
            <p class="txt">На основе нашего опыта и компетенций создаем персональное предложение для каждого клиента</p>
        </div>
    </div>
</div>

<div class="btn-wrapper">
    <app-custom-btn></app-custom-btn>
</div>

<div class="block__title contacts__title">
    <p class="h5">● Как нас найти</p>
    <h1 class="h2">Контакты</h1>
    <p class="txt center-align">Наша команда будет рада ответить на ваши вопросы</p>
</div>

<div class="contact__column w-row">
    <div class="contact__column_content w-col w-col-5">
        <div class="contact__list">
            <div class="contact__list_item">
                <img src="../../assets/mail.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Email</h1>
                    <p class="txt">Клиентский сервис</p>
                    <p class="h5 left-align">infogi-solution@mail.ru</p>
                </div>
            </div>
            <div class="contact__list_item">
                <img src="../../assets/pin.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Офис</h1>
                    <p class="txt">Юридический адрес</p>
                    <p class="h5 left-align">108802, Г.Москва, вн.тер.г. поселение Сосенское, <br> д Сосенки, ул
                        Ясеневая, д. 7, помещ. 4, 4А</p>
                </div>
            </div>
            <div class="contact__list_item">
                <img src="../../assets/phone.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Телефон</h1>
                    <p class="txt">Мы на связи в рабочее время</p>
                    <p class="h5 left-align">+7 (495) 0-555-245</p>
                </div>
            </div>
        </div>
    </div>
    <div class="contact__column_img w-col w-col-7 map_script">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.8784500096635!2d37.43743007709662!3d55.56931500610877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414aacb5bba3e805%3A0xfebf00f2faf5fc78!2z0K_RgdC10L3QtdCy0LDRjyDRg9C7LiwgNywg0KHQvtGB0LXQvdC60LggKNCd0L7QstC-0LzQvtGB0LrQvtCy0YHQutC40Lkg0JDQniDQnNC-0YHQutCy0LApLCDQnNC-0YHQutCy0LAsINCg0L7RgdGB0LjRjywgMTQyNzcw!5e0!3m2!1sru!2snl!4v1705266513732!5m2!1sru!2snl"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
<div class="block__description">
    <div class="columns w-row">
        <div class="column__description w-col w-col-5">
            <p class="txt__small">Общество с ограниченной ответственностью <br> «Джи&nbsp;Ай&nbsp;Солюшенс»</p>
        </div>
        <div class="column__description without__padding w-col w-col-4">
            <p class="txt__small">ОГРН 1217700443883 от&nbsp;20&nbsp;сентября&nbsp;2021&nbsp;года</p>
        </div>
        <div class="column__description w-col w-col-3">
            <p class="txt__small">КПП 775101001</p>
            <p class="txt__small">ИНН 7751205702</p>
        </div>
    </div>
</div>

