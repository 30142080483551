import { Component, Input } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() activeRoute = '';
  menuIsOpen = false;

  platformLink = '';

  constructor(config:ConfigService){
    this.platformLink = config.platformLink;
  }
  
  openMobMenu(){
    this.menuIsOpen=!this.menuIsOpen;
  }
}
