import { Component } from '@angular/core';

@Component({
  selector: 'app-raskritie',
  templateUrl: './raskritie.component.html',
  styleUrls: ['./raskritie.component.scss']
})
export class RaskritieComponent {
  prevAnswer = 0;

  openAnswer(e:any){
    let wrapper = e.target;

    wrapper.classList.contains('faq-question__active') ? wrapper.classList.remove('faq-question__active') : wrapper.classList.add('faq-question__active');
  }
}
