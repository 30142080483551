import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  isDocsShowing:boolean = false;

  openDocModal1() {
    let docModal1 = document.getElementById('docModal1');
    docModal1?.classList.add('_active-modal');
  }
  closeDocModal1() {
    let docModal1 = document.getElementById('docModal1');
    docModal1?.classList.remove('_active-modal');
  }

  openDocModal2() {
    let docModal2 = document.getElementById('docModal2');
    docModal2?.classList.add('_active-modal');
  }
  closeDocModal2() {
    let docModal2 = document.getElementById('docModal2');
    docModal2?.classList.remove('_active-modal');
  }

  openDocModal3() {
    let docModal3 = document.getElementById('docModal3');
    docModal3?.classList.add('_active-modal');
  }
  closeDocModal3() {
    let docModal3 = document.getElementById('docModal3');
    docModal3?.classList.remove('_active-modal');
  }

  openDocModal4() {
    let docModal3 = document.getElementById('docModal4');
    docModal3?.classList.add('_active-modal');
  }
  closeDocModal4() {
    let docModal3 = document.getElementById('docModal4');
    docModal3?.classList.remove('_active-modal');
  }

  showDocs(){
    this.isDocsShowing = !this.isDocsShowing;
  }

}
