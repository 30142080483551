<section class="section__footer">
    <div class="section__content-wrapper">
        <div class="w-row">
            <div class="footer__nav w-col w-col-6">
                <a routerLink="/" class="brand w-nav-brand" rel="home" aria-current="page"><img
                        width="200" height="36" src="../../../assets/logo.svg"
                        class="img__logo" alt="Джи Ай Солюшенс" decoding="async"></a>
                <p class="txt small">
                    © 2023 - 2024, ООО "Джи Ай Солюшенс", официальный сайт </p>
            </div>
            <div class="footer__nav w-col w-col-2">
                <div class="footer__nav">
                    <a routerLink="/about" class="btn__nav_footer w-button">О компании</a>
                    <a routerLink="/contacts" class="btn__nav_footer w-button">Контакты</a>
                    <a routerLink="/raskritie" class="btn__nav_footer w-button">Раскрытие информации</a>
                </div>
            </div>
            <div class="footer__nav w-col w-col-2">
                <a href="mailto:infogi-solution@mail.ru" class="btn__nav_footer w-button">infogi-solution@mail.ru</a>
                <app-custom-btn ></app-custom-btn>
            </div>
            <div class="footer__nav w-col w-col-2">
                <a href="tel:+74950555245" class="btn__phone_footer w-button">+7 (495) 0-555-245</a>
            </div>
        </div>
    </div>
</section>