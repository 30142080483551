import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  platformLink = 'https://www.tinkoff.ru/business/contractor/legal/1217700443883/'

  constructor() { }


}
