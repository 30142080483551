<div data-collapse="medium" class="header w-nav">
    <div class="container w-container">
        <a routerLink="/" class="brand w-nav-brand" rel="home" aria-current="page"><img width="200"
                height="36" src="../../../assets/logo.svg" class="img__logo"
                alt="Джи Ай Солюшенс" decoding="async"></a>
        <nav class="nav-menu w-nav-menu">
            <a routerLink="/about" [ngClass]="{'active-nav': activeRoute === 'about' ? true : false}" class="btn__nav_header w-nav-link">О компании</a>
            <a routerLink="/contacts" [ngClass]="{'active-nav': activeRoute === 'contacts' ? true : false}" class="btn__nav_header w-nav-link">Контакты</a>
            <a routerLink="/raskritie" [ngClass]="{'active-nav': activeRoute === 'raskritie' ? true : false}" class="btn__nav_header w-nav-link">Раскрытие информации</a>
            <a class="btn-item last">
                <img src="../../../assets/door.svg" alt="">
                <span class="insert"><a href="https://workspace.investments-today.click/">Войти</a></span>
            </a>
            <a href="tel:+74950555245" class="btn__phone_header w-button">+7 (495) 0-555-245</a>
        </nav>
        <div class="menu-button w-nav-button" (click)="openMobMenu()" [ngClass]="{'w--open':menuIsOpen}">
            <div class="nav-menu-wrap">
                <div class="icon w-icon-nav-menu"></div>
            </div>
        </div>
        <div class="w-nav-overlay" [ngClass]="{'visible':menuIsOpen}">
            <nav role="navigation" class="nav-menu w-nav-menu" data-nav-menu-open="">
                <a routerLink="/about" [ngClass]="{'active-nav': activeRoute === 'about' ? true : false}" class="btn__nav_header w-nav-link w--nav-link-open">О компании
                </a>
                <a routerLink="/contacts" [ngClass]="{'active-nav': activeRoute === 'contacts' ? true : false}" class="btn__nav_header w-nav-link w--nav-link-open">Контакты
                </a>
                <a routerLink="/raskritie" [ngClass]="{'active-nav': activeRoute === 'raskritie' ? true : false}"
                    class="btn__nav_header w-nav-link w--nav-link-open">Раскрытие информации </a>
                <a href="tel:+74950555245" class="btn__phone_header w-button">+7 (495) 0-555-245</a>
                <a class="btn-item last" href="#">
                    <img src="../../../assets/door.svg" alt="">
                    <span class="insert"><a href="https://workspace.investments-today.click/">Войти</a></span>
                </a>
            </nav>
        </div>
    </div>
</div>