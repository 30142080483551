<app-header activeRoute="contacts"></app-header>
<div class="block__title">
    <p class="h5">● GI Solutions</p>
    <h1 class="h2">Контакты</h1>
    <p class="txt center-align">Наша команда будет рада ответить на ваши вопросы</p>
</div>
<div class="contact__column w-row">
    <div class="contact__column_content w-col w-col-5">
        <div class="contact__list">
            <div class="contact__list_item">
                <img src="https://gi-solutions.ru/wp-content/uploads/2023/11/mail.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Email</h1>
                    <p class="txt">Клиентский сервис</p>
                    <p class="h5 left-align">infogi-solution@mail.ru</p>
                </div>
            </div>
            <div class="contact__list_item">
                <img src="https://gi-solutions.ru/wp-content/uploads/2023/11/pin.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Офис</h1>
                    <p class="txt">Юридический адрес</p>
                    <p class="h5 left-align">108802, Г.Москва, вн.тер.г. поселение Сосенское, <br> д Сосенки, ул
                        Ясеневая, д. 7, помещ. 4, 4А</p>
                </div>
            </div>
            <div class="contact__list_item">
                <img src="https://gi-solutions.ru/wp-content/uploads/2023/11/phone.svg" class="icon__contact" alt="">
                <div class="contact__list_content">
                    <h1 class="h4">Телефон</h1>
                    <p class="txt">Мы на связи в рабочее время</p>
                    <p class="h5 left-align">+7 (495) 0-555-245</p>
                </div>
            </div>
        </div>
    </div>
    <div class="contact__column_img w-col w-col-7 map_script">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.8784500096635!2d37.43743007709662!3d55.56931500610877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414aacb5bba3e805%3A0xfebf00f2faf5fc78!2z0K_RgdC10L3QtdCy0LDRjyDRg9C7LiwgNywg0KHQvtGB0LXQvdC60LggKNCd0L7QstC-0LzQvtGB0LrQvtCy0YHQutC40Lkg0JDQniDQnNC-0YHQutCy0LApLCDQnNC-0YHQutCy0LAsINCg0L7RgdGB0LjRjywgMTQyNzcw!5e0!3m2!1sru!2snl!4v1705266513732!5m2!1sru!2snl"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
<div class="block__description">
    <div class="columns w-row">
        <div class="column__description w-col w-col-5">
            <p class="txt__small">Общество с ограниченной ответственностью <br> «Джи&nbsp;Ай&nbsp;Солюшенс»</p>
        </div>
        <div class="column__description without__padding w-col w-col-4">
            <p class="txt__small">ОГРН 1217700443883 от&nbsp;20&nbsp;сентября&nbsp;2021&nbsp;года</p>
        </div>
        <div class="column__description w-col w-col-3">
            <p class="txt__small">КПП 775101001</p>
            <p class="txt__small">ИНН 7751205702</p>
        </div>
    </div>
</div>