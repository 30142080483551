import { Component } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  
  platformLink = '';

  constructor(config:ConfigService){
    this.platformLink = config.platformLink;
  }

  openBank() {
    window.open(this.platformLink, '_blank')
  }
}
